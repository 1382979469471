import * as validator from 'validate.js'
import scrollTo from '@common/scroll-to'

validator.validators.checkboxGroup = function(_, group) {
  if (group) {
    const checked = group.querySelectorAll('input[type=checkbox]:checked')

    if (checked.length) {
      return null
    }
  }

  return '^Please select one'
}

const findFieldWrapper = (el) => {
  if (el) {
    if (el.closest('.form-field-wrapper')) {
      return el.closest('.form-field-wrapper')
    }

    if (el.closest('.form-field')) {
      return el.closest('.form-field')
    }

    if (el.closest('.middleColumn')) {
      return el.closest('.middleColumn')
    }

    if (el.closest('.field')) {
      return el.closest('.field')
    }
  }
}

export const handleBlur = (e) => {
  const fieldWrapper = findFieldWrapper(e.target)

  if (fieldWrapper) {
    fieldWrapper.classList.remove('has-error')

    const existingErrorMessage = fieldWrapper.querySelector('.form-field-message')

    if (existingErrorMessage) {
      existingErrorMessage.remove()
    }
  }

  if (e.target.classList.contains('has-error')) {
    e.target.classList.remove('has-error')
  }
}

export const buildRules = (form) => {
  const rules = {}

  form
    .querySelectorAll('[required], input.required-if, .js-dropdown.required-if select, .optionset.required-if input')
    .forEach((field) => {
      // Ignore hidden fields
      if ((findFieldWrapper(field) && findFieldWrapper(field).offsetHeight === 0) || field.getAttribute('readonly')) {
        return
      }

      const fieldName = field.dataset.name ? field.dataset.name : field.name

      rules[fieldName] = {
        presence: {
          allowEmpty: false,
          message: '^Required',
        },
      }
    })

  form.querySelectorAll('[type="email"]').forEach((field) => {
    // Ignore hidden fields
    if (findFieldWrapper(field).offsetHeight === 0 || field.readonly) {
      return
    }

    if (field.name in rules) {
      rules[field.name].email = true
    } else {
      rules[field.name] = { email: true }
    }
  })

  form.querySelectorAll('[pattern]').forEach((field) => {
    // Ignore hidden fields
    if (findFieldWrapper(field).offsetHeight === 0 || field.readonly) {
      return
    }

    rules[field.name] = {
      format: {
        pattern: field.getAttribute('pattern'),
        message: '^Required',
      },
    }
  })

  form.querySelectorAll('[min]').forEach((field) => {
    // Ignore hidden fields
    if (findFieldWrapper(field).offsetHeight === 0 || field.readonly) {
      return
    }

    rules[field.name] = {
      length: {
        minimum: parseInt(field.min),
      },
    }
  })

  form.querySelectorAll('[data-equals]').forEach((field) => {
    // Ignore hidden fields
    if (findFieldWrapper(field).offsetHeight === 0 || field.readonly) {
      return
    }

    rules[field.name] = {
      equality: field.dataset.equals,
    }
  })

  form.querySelectorAll('[aria-required], [data-required]').forEach((field) => {
    // Ignore hidden fields
    if ((findFieldWrapper(field) && findFieldWrapper(field).offsetHeight === 0) || field.readonly) {
      return
    }

    if (field.classList.contains('optionset')) {
      const radio = field.querySelector('input[type=radio]')
      const checkbox = field.querySelector('input[type=checkbox]')

      if (radio) {
        rules[radio.name] = {
          presence: {
            message: '^Required',
          },
        }
      }

      if (checkbox) {
        rules[checkbox.name] = {
          checkboxGroup: checkbox.closest('.optionset'),
        }
      }
    }
  })

  form.querySelectorAll('.requiredcheckboxset').forEach((field) => {
    // Ignore hidden fields
    if ((findFieldWrapper(field) && findFieldWrapper(field).offsetHeight === 0) || field.readonly) {
      return
    }

    const checkbox = field.querySelector('input[type=checkbox]')

    if (checkbox) {
      rules[checkbox.name] = {
        checkboxGroup: checkbox.closest('.requiredcheckboxset'),
      }
    }
  })

  return rules
}

export const handleValidation = (form) => {
  const rules = buildRules(form)
  const result = validate(form, rules)

  if (result !== undefined) {
    for (const [name, messages] of Object.entries(result)) {
      const field = form.querySelector(`[name="${name}"]`)
      const fieldWrapper = findFieldWrapper(field)

      // Place error message
      if (fieldWrapper) {
        const existingErrorMessage = fieldWrapper.querySelector('.form-field-message')

        if (existingErrorMessage) {
          existingErrorMessage.remove()
        }

        const message = `
          <div class="form-field-message bad">
            ${messages[0]}
          </div>
        `
        fieldWrapper.insertAdjacentHTML('beforeend', message)
        fieldWrapper.classList.add('has-error')
      }

      field.classList.add('has-error')
    }

    const shouldScroll = form.dataset.formScroll !== undefined

    const firstError = form.querySelector('.has-error')

    if (shouldScroll && firstError) {
      scrollTo(firstError, 150, () => {
        setTimeout(() => {
          firstError.querySelector('input') && firstError.querySelector('input').focus()
        }, 0)
      })
    }

    return false
  }

  return true
}

export const validate = validator
