import IMask from 'imask/esm/imask'
import 'imask/esm/masked/pattern'
import { on, fire } from 'delegated-events'
import { handleValidation, handleBlur } from '@common/validation'
import ajax from '@common/ajax'
import scrollTo from '@common/scroll-to'
import { trackGoogleAnalytics } from '@common/util'

const setupDropdowns = async (form) => {
  const dropdowns = form.querySelectorAll('.js-dropdown')

  if (!dropdowns.length) {
    return
  }

  const { default: easydropdown } = await import('easydropdown-a11y')
  import('@styles/components/dropdown.css')

  dropdowns.forEach((el) => {
    const select = el.querySelector('select')

    if (!select) {
      return
    }

    const placeholder = el.querySelector('option[value=""]')

    if (placeholder && !placeholder.hasAttribute('data-placeholder')) {
      placeholder.setAttribute('data-placeholder', true)
    }

    const opts = {
      behavior: {
        openOnFocus: true,
        maxVisibleItems: 10,
        liveUpdates: true,
        useNativeUiOnMobile: false,
      },
    }

    easydropdown(select, opts)
  })
}

const setupMultiselects = async (form) => {
  const selects = form.querySelectorAll('select[multiple]')

  if (!selects.length) {
    return
  }

  const { default: TomSelect } = await import('tom-select')

  selects.forEach((el) => {
    new TomSelect(el, {
      plugins: {
        remove_button: {
          title: 'Remove this item',
        },
      },
      hidePlaceholder: true,
    })
  })
}

const setupMasks = (form) => {
  const fields = form.querySelectorAll('input[type="tel"]')

  const maskOptions = {
    mask: '(000) 000-0000',
  }

  fields.forEach((field) => {
    IMask(field, maskOptions)
  })
}

const setupSpecialFields = (form) => {
  // Setup file inputs
  form.querySelectorAll('input[type=file]').forEach((input) => {
    const placeholder = document.createElement('div')
    placeholder.classList.add('file-input-placeholder')

    if (input.value) {
      placeholder.textContent = input.files[0].name
    } else {
      placeholder.textContent = input.placeholder
    }

    input.parentNode.insertBefore(placeholder, input)

    placeholder.addEventListener('click', () => input.click())

    input.addEventListener('change', (e) => {
      placeholder.textContent = e.target.files[0].name
    })
  })
}

const setupUserForms = (form) => {
  var userforms = form.getElementsByClassName('userformsstep')

  Array.from(userforms).forEach((uf) => {
    var requireds = uf.getElementsByClassName('field requiredField')
    Array.from(requireds).forEach((rf) => {
      var label = rf.getElementsByTagName('label')
      if (rf.classList.contains('userformsoptionset') || rf.classList.contains('checkboxset')) {
        label = rf.getElementsByTagName('legend')
      }
      var html = label[0].innerHTML
      label[0].innerHTML = html + ' <sup>*</sup>'
    })
    var selects = uf.getElementsByTagName('select')
    Array.from(selects).forEach((s) => {
      s.parentNode.classList.add('js-dropdown')
    })
    var headings = uf.getElementsByClassName('FormHeading')
    Array.from(headings).forEach((h) => {
      h.classList.add(h.tagName.toLowerCase())
    })
    var htmls = uf.getElementsByClassName('composite')
    Array.from(htmls).forEach((html) => {
      html.classList.add('wysiwyg')
    })
  })
}

const handleToggle = (e) => {
  const form = e.target.closest('form')
  const field = e.target
  const name = e.target.name
  const selectedValue = e.target.value

  form.querySelectorAll(`[data-toggle-if^="${name}|"]`).forEach((input) => {
    const toggleGroup = input.closest('.toggle-group:not([data-toggle-if])')
    let shouldShow = false

    if (!toggleGroup) {
      return
    }

    if (field.type === 'radio' || field.type.includes('select')) {
      const targetValues = input.dataset.toggleIf.split('|')
      targetValues.shift()
      shouldShow = targetValues.indexOf(selectedValue) !== -1
    }

    if (field.type === 'checkbox') {
      shouldShow = field.checked
    }

    if (shouldShow) {
      toggleGroup.classList.remove('hidden')
    } else {
      toggleGroup.classList.add('hidden')

      toggleGroup.querySelectorAll('input:not([type=checkbox]):not([type=radio])').forEach((x) => (x.value = null))

      toggleGroup.querySelectorAll('input:checked').forEach((x) => {
        if (!toggleGroup.classList.contains('ignore-clear')) {
          x.checked = false
        }

        form.querySelectorAll(`[data-toggle-if^="${x.name}|"]`).forEach((input) => {
          const toggleGroup = input.closest('.toggle-group')
          toggleGroup.classList.add('hidden')

          toggleGroup.querySelectorAll('input:not([type=checkbox]):not([type=radio])').forEach((x) => (x.value = null))
        })
      })
    }
  })
}

const toggleSubmit = (submitButton) => {
  submitButton.disabled = !submitButton.disabled
  submitButton.classList.toggle('btn-loading')
}

const showFormMessages = (holder, message, success, autoHide) => {
  holder.style.display = 'block'
  holder.innerHTML = message

  if (success) {
    holder.classList.remove('form-message-error')
  } else {
    holder.classList.add('form-message-error')
  }

  if (autoHide) {
    setTimeout(() => {
      holder.style.display = 'none'
    }, 5000)
  }
}

const handleAjaxSubmit = (e) => {
  e.preventDefault()

  const form = e.target
  const isValid = handleValidation(form)

  if (isValid) {
    const formMessages = form.querySelector('.form-messages')
    const submitButton = form.querySelector('[type=submit]')
    let autoHideMessage = form.dataset.autoHideMessage ? form.dataset.autoHideMessage === 'true' : true
    toggleSubmit(submitButton)

    const formData = new FormData(form)

    ajax
      .url(form.action)
      .body(formData)
      .post()
      .json((res) => {
        if (res.success) {
          if (res.ga) {
            res.ga.forEach((event) => trackGoogleAnalytics(event))
          }

          if (form.dataset.formDisableReset === undefined) {
            form.reset()

            form.querySelectorAll('select').forEach((x) => (x.value = null))
            form.querySelectorAll('.toggle-fields').forEach((x) => x.classList.add('hidden'))
            form.querySelectorAll('.toggle-group').forEach((x) => x.classList.add('hidden'))
          }

          if (form.dataset.hideOnSubmit) {
            form.querySelectorAll('.form-content').forEach((x) => x.classList.add('hidden'))
            document.querySelectorAll('.hide-on-form-submit').forEach((x) => x.classList.add('invisible'))
            autoHideMessage = false

            const shouldScroll = form.dataset.formScroll !== undefined

            if (shouldScroll) {
              scrollTo(form, 32)
            }
          }

          if (document.querySelector('.hide-content-on-submit')) {
            document.querySelector('.hide-content-on-submit').classList.add('hidden')
          }

          // Allow other scripts to hook into this submit
          fire(document.documentElement, 'ajaxform:submit', {
            form: form,
            type: 'post',
            url: new URL(form.action),
            queryParameters: new URLSearchParams(formData).toString(),
          })

          if (res.filedownload) {
            window.location.href = res.filedownload
          }
        }

        if (res.message) {
          showFormMessages(formMessages, res.message, res.success, autoHideMessage)
        }
      })
      .catch(() => {
        showFormMessages(formMessages, 'Sorry, there was a problem with your submission')
      })
      .finally(() => {
        toggleSubmit(submitButton)
      })
  }
}

const handleSubmit = (e) => {
  const form = e.target
  const isValid = handleValidation(form)
  const submitButton = form.querySelector('[type=submit]')
  toggleSubmit(submitButton)

  if (!isValid) {
    e.preventDefault()
    toggleSubmit(submitButton)
  }
}

const setupForm = (form) => {
  if (form) {
    setupUserForms(form)
    setupDropdowns(form)
    setupMultiselects(form)
    setupMasks(form)
    setupSpecialFields(form)
  }
}

const init = () => {
  if (!document.documentElement.classList.contains('did-form-init')) {
    document.querySelectorAll('.styled-form').forEach((form) => {
      setupForm(form)
    })

    on('blur', '.has-error', handleBlur, { capture: true })
    on('change', '.has-error', handleBlur, { capture: true })
    on('change', '.has-error blur', handleBlur, {
      capture: true,
    })
    on('submit', '.styled-form[data-form-ajax]', handleAjaxSubmit)
    on('submit', '.styled-form:not([data-form-ajax])', handleSubmit)
    on('change', 'select', handleToggle)
    on('change', 'input[type=radio]', handleToggle)
    on('change', 'input[type=checkbox]', handleToggle)

    document.documentElement.classList.add('did-form-init')
  }
}

init()

export { setupForm }
